<template>
    <div class="container-box">
        <el-card class="box-card-bottom" style="height: 7.4rem;">
            <div class="step" style="margin-bottom: 10px;">
                <span @click="nextStep">下一步</span>
            </div>
            <el-tabs v-model="doubleDivisionTab" type="border-card" class="demo-tabs">
                <div class="right-box">
                    <button class="L_btn" @click="getExcel">报名模板下载</button>
                    <upload :callbackFile="callbackValue" :tip="tip" />
                </div>
                <el-tab-pane label="信息通信工程实践" name="1">
                    <el-table v-loading="loading" border :scrollbar-always-on="true" :data="tableData"
                        @selection-change="handleSelectionChange" style="width: 100%;border-radius: 0.05rem;">
                        <el-table-column type="index" sortable label="序号" width="60" />
                        <el-table-column prop="school" sortable label="学校全称" width="120" />
                        <el-table-column prop="province" sortable label="省份" />
                        <el-table-column prop="group" sortable label="组别" width="180" />

                        <el-table-column prop="name1" sortable label="参赛选手" width="180" />
                        <el-table-column prop="id_num1" sortable label="身份证号" width="180" />
                        <el-table-column prop="phone1" sortable label="手机号" width="180" />
                        <el-table-column prop="mail1" sortable label="邮箱" width="180" />
                        <el-table-column prop="name5" sortable label="带队教师" width="180" />
                        <el-table-column prop="phone5" sortable label="手机号" width="180" />
                    </el-table>
                </el-tab-pane>
            </el-tabs>

        </el-card>
    </div>
</template>
<script>
import axios from 'axios'; // 引入axios
import { userInfo } from "api/apis.js";
import upload from '@/components/upload/upload.vue';
import { ElMessage } from 'element-plus'
export default {
    name: 'DoubleDivision',
    components: {
        upload,
    },
    data() {
        return {
            tableData:[],
            loading:false,
            doubleDivisionTab:'1',
            nums:0,
            team_ids:[],
            type:'',
            track:'',
            group:'',

        }
    },
    created(){
        this.getUserNumber();
    },
    methods: {
        //信息通信工程实践
        getUserNumber() {
            let uid = this.$store.state.User.user_id
            console.log(this.$store.state);
            let dataobj = new FormData();
            dataobj.append("user_id", uid);
            // dataobj.append("page", this.page);//当前页数
            // dataobj.append("limit", this.pageSize);//一页显示多少条
            // dataobj.append("search_name", this.formInline.name);//姓名
            dataobj.append("status", '0');//状态 0:未审核 1:已审核
            dataobj.append("track", '教师');
            dataobj.append("type", '教师赛');
            this.loading = true;
            this.tableData = []
            this.team_ids = [];
            userInfo.selsetUser(dataobj).then(res => {
                this.nums = 0;
                this.loading = false;
                const { count, data } = res;
                this.total = count || 0;
                console.log(JSON.parse(data));
                if(JSON.parse(data).length > 0 ){
                    let resData = JSON.parse(data)[0];
                    this.type = resData.type;
                    this.track = resData.track;
                    this.group = resData.group;
                }
                let totalNums = [] //计算队员总数
                this.team_nums = +Number(JSON.parse(data).length);
                JSON.parse(data).forEach(m => {
                    totalNums.push(...m.players)
                })
                this.nums = totalNums.length;

                JSON.parse(data).forEach((ele, index) => {
                    let obj = {}
                    this.team_ids.push(ele.team_id) //保存团队id
                    obj.group = ele.group
                    obj.province = ele.province
                    obj.school = ele.school
                    // 队员信息
                    ele.players.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '1'] = item[key]
                            }
                        } else if (index === 1) {
                            for (let key in item) {
                                obj[key + '2'] = item[key]
                            }
                        }
                    })
                    //教师信息
                    // ele.teachers_adviser.forEach((item, index) => {
                    //     if (index === 0) {
                    //         for (let key in item) {
                    //             obj[key + '3'] = item[key]
                    //         }
                    //     } else if (index === 1) {
                    //         for (let key in item) {
                    //             obj[key + '4'] = item[key]
                    //         }
                    //     } else if (index === 2) {
                    //         obj.tphone3 = item.phone
                    //         obj.tmail3 = item.mail
                    //     }

                    // })
                    //带队教师信息
                    ele.teachers_leader.forEach((item, index) => {
                        if (index === 0) {
                            for (let key in item) {
                                obj[key + '5'] = item[key]
                            }
                        }

                    })
                    this.tableData.push(obj);
                })

            })
        },
        nextStep(nextNum) {
            var innovation = [];
            let total_nums = this.nums * 1;
            let total_team_nums = this.team_nums * 1;
            let engineeringPractice = this.tableData.slice(0);
            // let total_team_ids= [] = this.team_ids.concat(...this.vate_team_ids)
            let totalLength = this.tableData.length * 1
            let totalObj = {
                nums: total_nums,
                team_nums: total_team_nums,
                s_fee: '80',
            }
            // this.$router.push({ path: '/ViewDetails', query: { 'total_Obj': JSON.stringify(totalObj),team_ids:total_team_ids} }) //测试用
            if (totalLength === 0) {
                ElMessage({
                    message: "请先上传报名信息",
                    type: "error",
                });
                return
            }
            this.$router.push(
                    { 
                        path: '/ViewDetails', 
                        query: { 
                            'total_Obj': JSON.stringify(totalObj),
                            team_ids: this.team_ids,
                            engineeringPractice:JSON.stringify(engineeringPractice),
                            innovation,
                            competition_type_li:this.type,
                            track_li:this.track,
                            group_li:this.group,

                        }
                    })
            // this.$router.push({ path: '/ViewDetails', query: { 'total_Obj': JSON.stringify(totalObj), team_ids: this.team_ids } })
        },
        callbackValue(file) {
            let uid = this.$store.state.User.user_id
            this.formData = new FormData();//通过form数据格式来传
            this.formData.append("file", file); //选择文件
            this.formData.append("user_id", uid);
            this.formData.append("type", '教师赛');

            this.file = file
            userInfo.importUinfo(this.formData).then(res => {
                console.log(res.filename, 'res.filename');
                if (res.filename) {
                    let url = res.filename;
                    let obj = new FormData();//通过form数据格式来传
                    obj.append("file_path_name", url); //文件名
                    axios.post('api/file/download', obj, { responseType: 'blob' }).then(res => {
                        const blob = new Blob([res.data], { type: "application/vnd.ms-excel" })
                        const downloadElement = document.createElement('a');
                        const href = window.URL.createObjectURL(blob); //创建下载的链接
                        downloadElement.href = href;
                        downloadElement.download = 'error_info.xlsx'; //下载后文件名
                        document.body.appendChild(downloadElement);
                        downloadElement.click(); //点击下载
                        document.body.removeChild(downloadElement); //下载完成移除元素
                        window.URL.revokeObjectURL(href); //释放掉blob对象;
                        return
                    })
                }
                this.tip = this.file.name + ' ' + res.msg;
                ElMessage({
                    message: "文件" + res.msg,
                    type: "success",
                });
                this.tableData = []
                this.getUserNumber();

                // this.url = res.file_path_name;
            })
        },
        // 模板下载
        getExcel() {
            let a = document.createElement('a');
            let evt = document.createEvent('MouseEvents');
            a.download = `./static/Excel/DoubleDivision.xlsx`;
            a.href = `./static/Excel/DoubleDivision.xlsx`;
            evt.initEvent('click', true, true);
            a.dispatchEvent(evt);
            window.URL.revokeObjectURL(a.href);
            
        },
    }
}
</script>

<style lang="scss" scoped>
.container-box {
    width: 95%;
    margin: auto;
}
.box-card-bottom {
    // overflow-y: scroll;
}

.formWeight {
    display: flex;
}

.demo-form-inline {
    flex: 3;
}
.decorationBox{

    color: blue;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.right-box {
    display: flex;
    justify-content: left;
    flex: 2
}

.dt_title {
    display: inline-block;
    color: #3D3D3D;
    font-size: 0.22rem;
    margin-right: 0.2rem;
}

.L_btn {
    width: 1.16rem;
    height: 0.32rem;
    border: 0;
    background-color: #C45454;
    border-radius: 0.04rem;
    font-size: 0.14rem;
    color: white;
    // margin-right: 0.2rem;
    cursor: pointer;
}

.R_btn {}

.pagination-box {
    display: flex;
    margin-top: 0.15rem;
}

.el-pagination {
    flex: 2;
}

.step {
    flex: 1;
    display: flex;
    justify-content: right;

    span {
        display: block;
        width: 1.71rem;
        height: 0.45rem;
        font-size: 0.24rem;
        text-align: center;
        line-height: 0.45rem;
        color: white;
        background-color: #436E8A;
        border-radius: 0.04rem;
        cursor: pointer;
    }
}
</style>